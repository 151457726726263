import useWindowWidth from '../hooks/useWindowWidth';

export default function SectionPageTitle({ pageTitle, subTitle, bgImg }) {
	const windowWidth = useWindowWidth();

	if(bgImg) {
		let bgImgPost = windowWidth > 810 ? bgImg.h : bgImg.v;
		return (
			<section className="page-title p-0">
				<div className="container">					
					<div className="post-page-title" style={{ '--bg-post-page-title': `url(${bgImgPost})` }}>
						<h1 className="mb-1">{pageTitle}</h1>
						{subTitle &&
							<h3>{subTitle}</h3>
						}
					</div>
				</div>
			</section>
		);
	} else {
		return (
			<section className="page-title">
				<div className="container">
					<h1 className="mb-0">{pageTitle}</h1>
					{subTitle &&
						<h3>{subTitle}</h3>
					}
				</div>
			</section>
		);
	}
}